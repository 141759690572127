// @flow
import Cookies from 'js-cookie'
import * as actionTypes from './actions'
import { composeWithDevTools } from 'redux-devtools-extension'
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.mudey.pt'

const token = Cookies.get('MUDEY_token', { domain: domain }) || null
const uuid = Cookies.get('MUDEY_uuid', { domain: domain }) || null
const email = Cookies.get('MUDEY_email', { domain: domain }) || null
const name = Cookies.get('MUDEY_name', { domain: domain }) || null

const user = token
  ? {
      email,
      name,
      uuid
    }
  : null

const initialState = {
  isLoading: false,
  journeyId: null,
  comparePackages: [],
  slug: '',
  user: user,
  token: token,
  isAuthenticated: token ? true : false,
  intendedSum: null,
  status: 'out',
  stripe_payment: null,
  questionnarie_id: null,
  questionnarie_active_panel_index: 0,
  questionnarie_additional_data: null,
  policyCost: null,
  health_insurance_data: [],
  health_packages: null,
  car_filters: [],
  life_insurance_data: [],
  isMobile: false,
  personal_accidents_journey_id: null,
  personal_accidents_packages: [],
  housekeeper_packages: [],
  car_packages: null,
  criticalIllness_packages: null,
  hospitalization_packages: null,
  birthdatesArray: null,
  car_data: null,
  carPackagesCalled: false
}

const reducer = (state: Object = initialState, action: Object) => {
  const { payload, type, birthdate } = action
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        user: payload.user,
        status: 'in'
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        status: 'out'
      }
    case actionTypes.UPDATE_COMPARE_PACKAGES:
      return {
        ...state,
        journeyId: payload.journeyId,
        comparePackages: payload.packages,
        slug: payload.slug
      }
    case actionTypes.SET_JOURNEY_ID:
      return {
        ...state,
        journeyId: payload
      }
    case actionTypes.SET_JOURNEY_INTENDED_SUM:
      return {
        ...state,
        intendedSum: payload.intendedSum
      }
    case actionTypes.STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        stripe_payment: 'success'
      }
    case actionTypes.STRIPE_PAYMENT_PROGRESS:
      return {
        ...state,
        stripe_payment: 'progress'
      }
    case actionTypes.STRIPE_PAYMENT_FAIL:
      return {
        ...state,
        stripe_payment: 'fail'
      }
    case actionTypes.STRIPE_PAYMENT_CLEAR:
      return {
        ...state,
        stripe_payment: null
      }
    case actionTypes.IN:
      return {
        ...state,
        status: 'in'
      }
    case actionTypes.SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        questionnarie_id: payload
      }
    case actionTypes.SET_QUESTIONNAIRE_ACTIVE_PANEL_INDEX:
      return {
        ...state,
        questionnarie_active_panel_index: payload
      }
    case actionTypes.SET_HEALTH_QUESTIONNARIE_ADDITIONAL_DATA:
      return {
        ...state,
        questionnarie_additional_data: payload
      }
    case actionTypes.SET_POLICY_COST:
      return {
        ...state,
        policyCost: payload
      }

    case actionTypes.SET_HEALTH_INSURANCE_DATA:
      return {
        ...state,
        health_insurance_data: payload
      }

    case actionTypes.SET_HEALTH_INSURANCE_FILTERS:
      return {
        ...state,
        health_insurance_data: {
          ...state.health_insurance_data,
          additionalCover: payload
        }
      }

    case actionTypes.SET_CAR_INSURANCE_FILTERS:
      return {
        ...state,
        car_filters: payload
      }

    case actionTypes.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: payload
      }

    case actionTypes.SET_PERSONAL_ACCIDENTS_PACKAGES:
      return {
        ...state,
        personal_accidents_packages: payload
      }

    case actionTypes.SET_HOUSEKEEPER_PACKAGES:
      return {
        ...state,
        housekeeper_packages: payload
      }

    case actionTypes.SET_PERSONAL_ACCIDENTS_JOURNEY_ID:
      return {
        ...state,
        personal_accidents_journey_id: payload
      }
    case actionTypes.SET_CRITICALILLNESS_PACKAGES:
      return {
        ...state,
        criticalIllness_packages: payload
      }
    case actionTypes.SET_HOSPITALIZATION_PACKAGES:
      return {
        ...state,
        hospitalization_packages: payload
      }
    case actionTypes.SET_CRITICALILLNESS_BIRTHDATES:
      return {
        ...state,
        birthdatesArray: payload
      }
    case actionTypes.SET_CAR_PACKAGES:
      return {
        ...state,
        car_packages: payload
      }

    case actionTypes.SET_CAR_DATA:
      return {
        ...state,
        car_data: payload
      }
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case actionTypes.SET_CAR_PACKAGES_CALLED:
      return {
        ...state,
        carPackagesCalled: payload
      }
    case actionTypes.SET_HEALTH_PACKAGES:
      return {
        ...state,
        health_packages: payload
      }
    default:
      return state
  }
}

export default reducer
