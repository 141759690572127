// Auth Routes (Login required)
import CreditcardRedirect from "../containers/state-page/redirect-cc";
import React from "react";

// Public Routes
const Login = React.lazy(() => import("../containers/user/login"));
const Register = React.lazy(() => import("../containers/user/register"));
const Forgot = React.lazy(() => import("../containers/user/forgot"));
const Recovery = React.lazy(() => import("../containers/user/recovery"));
const Home = React.lazy(() => import("../containers/home"));
const FutureProofJourney = React.lazy(() =>
  import("../containers/journeys/FutureProof/index")
);
const FutureProofThankYou = React.lazy(() => import("../containers/thank-you/FutureProof"));

const FutureProofResults = React.lazy(() =>
  import("../containers/results/results")
);
const FutureProofSummary = React.lazy(() => import("../containers/summary/FutureProof"));

const NotFoundPage = React.lazy(() =>
  import("../containers/state-page/not-found")
);

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    authRequired: false,
  },
  {
    path: "/not-found",
    exact: true,
    name: "NotFoundPage",
    component: NotFoundPage,
    authRequired: false,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    authRequired: false,
  },
  {
    path: "/register",
    exact: true,
    name: "Register",
    component: Register,
    authRequired: false,
  },
  {
    path: "/forgot",
    exact: true,
    name: "Forgot",
    component: Forgot,
    authRequired: false,
  },
  {
    path: "/restore",
    exact: true,
    name: "Recovery",
    component: Recovery,
    authRequired: false,
  },

  {
    path: "/seguro-vida-protecao/resultados/pacotes",
    exact: true,
    name: "FutureProofResults",
    component: FutureProofResults,
    authRequired: false,
  },

  {
    path: "/seguro-vida-protecao/simulacao",
    exact: true,
    name: "FutureProofJourney",
    component: FutureProofJourney,
    authRequired: false,
  },
  {
    path: "/seguro-vida-protecao/resultados/summary/:orderId?",
    exact: true,
    name: "FutureProofSummary",
    component: FutureProofSummary,
    authRequired: false,
  },
  {
    path: "/seguro-vida-protecao/compra/obrigado/:orderId",
    exact: true,
    name: "FutureProofThankYou",
    component: FutureProofThankYou,
    authRequired: false,
  },
];

export default routes;
