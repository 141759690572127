export default `
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.5rem;
  padding: 5px;
  cursor: pointer;
  outline: none;
  border: 1px solid #0293E8;
  &:hover {
    border: 1px solid #b5b5b5;
  }
  `

export const behaviours = {
  disabled: `
  opacity: 0.5;
  cursor: default;
  &:hover {
    background-color: #0293E8;
    color: white;
    cursor: default;
  }
  `
}

export const modifiers = {
  size: {
    normal: `
    padding: 6px;
    font-size: 0.65rem;
    `,
    large: `
    padding: 15px;
    font-size: 1rem;`
  },
  color: {
    blue: `
    background-color: #aacecd;
    color: white;
    border-color: #aacecd;`,
    green: `
    background-color: #aacecd;
    color: white;
    border-color: #aacecd;`,
    purple: `
    background-color: #0293E8;
    color: white;
    `,
    transparent: `
    background-color: white;
    color: #0293E8;
    border: none;`,
    yellow: `
    background-color: #f7c98b;
    color: white;
    border-color: #f7c98b;`,
  }
}
