// @flow
import { useState, useEffect } from "react";
let id = null;
const titles = {
  "seguro-vida-protecao": {
    simulacao: "Future Proof | Seguro Vida | Questionário",
    resultados: {
      pacotes: "Future Proof | Seguro Vida | Resultados",
      summary: "Future Proof | Seguro Vida | Pagamento",
    },
    compra: {
      pagamento: "Future Proof | Seguro Vida | Pagamento",
      obrigado: "Future Proof | Seguro Vida | Compra",
      beneficiarios: "",
      "thank-you": "Future Proof | Seguro Vida | Compra",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
  },
};

export const titleManager = (location: any): void => {
  if (location && location.pathname) {
    // id = localStorage.getItem("journeyID");
    // titles["seguro-iob"][id] = "IOB | Seguro E&O | Questionário";
    const root = location.pathname.split("/")[1] || "";
    const firstKey = location.pathname.split("/")[2] || "";
    const lastKey = location.pathname.split("/")[3] || "";
    const title = () => {
      if (lastKey) {
        return titles[root][firstKey][lastKey]
        // ||null
        || "Future Proof | Seguro Vida | Questionário";
      }
      if (!lastKey && firstKey) {
        return titles[root][firstKey] || null;
      }
      return null;
    };

    const docTitle = title();

    if (docTitle) {
      document.title = docTitle;
    } else {
      document.title = "Future Proof";
    }
  } else {
    document.title = "Future Proof";
  }
};
