import { gql } from '@apollo/client'

export const GET_USER = gql`
  {
    getUser(userId: 1) {
      address
      birthdate
      civilStatus
      driverLicenseDate
      email
      firstName
      idCard
      isDeleted
      lastName
      licenseNumber
      loginType
      postalCode
      profession
      token
      userId
      username
      vat
      vehicles {
        brand
        model
        version
        driver
        inspectionDate
        plateNumber
        taxDate
        type
      }
    }
  }
`

export const LOGIN_USER = gql`
  query userLogin($companyId: String, $managerId: String, $consents: UserConsentsInput) {
    userLogin(input: { companyId: $companyId, managerId: $managerId, consents: $consents}) {
      uuid
      firstName
      lastName
      email
      companyId
      managerId
    }
  }
`

export const getProfessions = gql`
  query {
    getProfessions {
      professions
    }
  }
`

export const GET_PROVIDERS = gql`
  query getProviders($type: String) {
    getProviders(type: $type) {
      insuranceCompany
      name
    }
  }
`
