// @flow
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOADING = 'LOADING'
export const IN = 'IN'
export const UPDATE_COMPARE_PACKAGES = 'UPDATE_COMPARE_PACKAGES'
export const SET_JOURNEY_INTENDED_SUM = 'SET_JOURNEY_INTENDED_SUM'
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS'
export const STRIPE_PAYMENT_PROGRESS = 'STRIPE_PAYMENT_PROGRESS'
export const STRIPE_PAYMENT_FAIL = 'STRIPE_PAYMENT_FAIL'
export const STRIPE_PAYMENT_CLEAR = 'STRIPE_PAYMENT_CLEAR'
export const SET_QUESTIONNAIRE_ID = 'SET_QUESTIONNAIRE_ID'
export const SET_QUESTIONNAIRE_ACTIVE_PANEL_INDEX = 'SET_QUESTIONNAIRE_ACTIVE_PANEL_INDEX'
export const SET_HEALTH_QUESTIONNARIE_ADDITIONAL_DATA = 'SET_HEALTH_QUESTIONNARIE_ADDITIONAL_DATA'
export const SET_POLICY_COST = 'SET_POLICY_COST'
export const SET_HEALTH_INSURANCE_DATA = 'SET_HEALTH_INSURANCE_DATA'
export const SET_HEALTH_INSURANCE_FILTERS = 'SET_HEALTH_INSURANCE_FILTERS'
export const SET_CAR_INSURANCE_FILTERS = 'SET_CAR_INSURANCE_FILTERS'
export const SET_LIFE_INSURANCE_FILTERS = 'SET_LIFE_INSURANCE_FILTERS'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_PERSONAL_ACCIDENTS_PACKAGES = 'SET_PERSONAL_ACCIDENTS_PACKAGES'
export const SET_HOUSEKEEPER_PACKAGES = 'SET_HOUSEKEEPER_PACKAGES'
export const SET_PERSONAL_ACCIDENTS_JOURNEY_ID = 'SET_PERSONAL_ACCIDENTS_JOURNEY_ID'
export const SET_CAR_PACKAGES = 'SET_CAR_PACKAGES'
export const SET_HOSPITALIZATION_PACKAGES = 'SET_HOSPITALIZATION_PACKAGES'
export const SET_CRITICALILLNESS_PACKAGES = 'SET_CRITICALILLNESS_PACKAGES'
export const SET_CRITICALILLNESS_BIRTHDATES = 'SET_CRITICALILLNESS_BIRTHDATES'
export const SET_CAR_DATA = 'SET_CAR_DATA'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_JOURNEY_ID = 'SET_JOURNEY_ID'
export const SET_CAR_PACKAGES_CALLED = 'SET_CAR_PACKAGES_CALLED'
export const SET_HEALTH_PACKAGES = 'SET_HEALTH_PACKAGES'

export const updateComparePackageBasket = (payload: Object) => async (dispatch: Function) => {
  dispatch({ type: 'UPDATE_COMPARE_PACKAGES', payload })
}
