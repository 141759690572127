// @flow

import style, { behaviours, modifiers } from './style'

import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  ${style}
  ${(props: Object) => modifiers.size[props.size]}
  ${(props: Object) => modifiers.color[props.color]}
  ${(props: Object) => (props.disabled ? behaviours[props.disabled] : '')}
`

type ButtonType = {
  color: 'purple' | 'transparent' | 'green' | 'yellow' | 'blue',
  children: any,
  disabled: boolean,
  onClick?: Function,
  size: 'normal' | 'large',
  type: string,
  styles?: any
}

export default function Button(props: ButtonType) {
  const { disabled, color, size, onClick, type, styles } = props
  return (
    <StyledButton
      style={styles || null}
      color={color}
      disabled={disabled}
      onClick={props.disabled ? null : onClick}
      size={size}
      type={type}>
      {props.children}
    </StyledButton>
  )
}

Button.defaultProps = {
  color: 'purple',
  default: false,
  disabled: false,
  onClick: null,
  size: 'normal',
  type: 'submit'
}
