// @flow
import { store } from '../store'
import Cookies from 'js-cookie'
// import firebase from 'firebase/app'
import { store as Notify } from 'react-notifications-component'

const env = process.env.NODE_ENV
const domain = env === 'development' ? 'localhost' : '.mudey.pt'

export const apiErrorHandler = (error: any) => {
  const message = error.message || ''
  if (message.includes('sessão')) {
    Cookies.remove('MUDEY_token', { domain: domain })
    Cookies.remove('MUDEY_wallet_token', { domain: domain })
    Cookies.remove('MUDEY_wallet_token')
    Cookies.remove('MUDEY_uuid', { domain: domain })
    Cookies.remove('MUDEY_email', { domain: domain })
    Cookies.remove('MUDEY_name', { domain: domain })
    Cookies.remove('MUDEY_wallet_uuid', { domain: domain })
    Cookies.remove('MUDEY_wallet_email', { domain: domain })
    Cookies.remove('MUDEY_wallet_name', { domain: domain })
    store.dispatch({
      type: 'LOGOUT'
    })
    // firebase.auth().signOut()
    Notify.addNotification({
      title: 'Ops... !',
      message: errors.ERROR_AUTHENTICATION,
      type: 'danger',
      insert: 'top',
      container: window.innerWidth > 768 ? 'top-right' : 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 7000
      }
    })
  } else {
    let errorMessage =
      error && error.message ? error.message.replace('GraphQL error:', '') : 'Something went wrong sorry...'
    if (errorMessage.includes('Unexpected token < in JSON at position 0')) {
      errorMessage = 'Ainda estamos a receber preços das seguradoras.'
    }
    Notify.addNotification({
      title: 'Ops... !',
      message: errorMessage,
      type: 'danger',
      insert: 'top',
      container: window.innerWidth > 768 ? 'top-right' : 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 7000
      }
    })
  }
}

const errors = {
  ACCOUNT_HOLDER_EMPTY: 'Nome de conta encontra-se vazio',
  EMAIL_EMPTY: 'Email encontra-se vazio',
  ERROR_AUTHENTICATION: 'A tua sessão esteve inativa muito tempo e expirou. Entra novamente',
  ERROR_CC_TRANSACTION: 'Erro a processar o pagamento com cartão de crédito',
  ERROR_CONFIRMING_TRANSACTON: 'Erro a confirmar a transação',
  ERROR_CREATE_CC_PAYMENT: 'Erro ao gerar pagamento por cartão de crédito.',
  ERROR_CREATE_MB_PAYMENT: 'Erro ao gerar pagamento.',
  ERROR_CREATE_MB_REFERENCE: 'Erro ao criar referencia multibanco.',
  ERROR_CREATE_ORDER: 'Erro ao criar Encomenda.',
  ERROR_CREATE_POLICY: 'Erro ao criar apólice.',
  ERROR_GET_PENDING_ORDERS: 'Erro a obter pagamentos pendentes',
  ERROR_GENERATING_PDF: 'Erro a gerar PDF',
  ERROR_NOT_SUPPORTED_PAYMENT: 'Pagamento não suportado',
  ERROR_SET_PAY_ORDER: 'Erro a gerar encomenda',
  ERROR_VALIDATE_ORDER: 'Erro ao validar Encomenda.',
  FTP_CONNECTION_ERROR: 'Não foi possível comunicar com o servidor de FTP.',
  IBAN_EMPTY: 'Iban encontra-se vazio',
  NAME_EMPTY: 'Nome encontra-se vazio',
  ORDER_NOT_FOUND: 'Encomenda não encontrada.',
  ORDER_PAYMENT_INPROGRESS: 'Pagamento de encomenda já se encontra em curso',
  INVALID_ORDER_PRICE: 'Preço de pacote inválido fornecido',
  PHONE_EMPTY: 'Telefone encontra-se vazio',
  POLICY_NOT_FOUND: 'Apólice não encontrada.',
  VALIDATE_ERROR: 'Erro ao validar',
  VALUE_EMPTY: 'Valor encontra-se vazio.',
  ERROR_UNEXPECTED: 'Erro inesperado.',
  UNABLE_TO_CONTACT_EASYPAY: 'Não conseguimos contactar o gateway de pagamentos.',
  NO_STATUS_PROVIDED: 'Nenhum status fornecido',
  UNKOWN_STATUS: 'Estado de encomenda inválido',
  JOURNEY_TYPE_UNDEFINED: 'Tipo de jornada inválido',
  ERROR_FETCHING_PAYMENTS: 'Impossível obter pagamentos',
  ORDER_ERROR: 'Preço do pedido inválido',
  INVALID_JOURNEY_DATA: 'Dados inválidos de json de jornada',
  PACKAGE_PRICE_UNDEFINED: 'Preço do pacote indefinido ou a zero!',
  INVALID_EMAIL: 'Email Inválido',
  INVALID_NIF: 'NIF Inválido',
  INVALID_postalCode: 'Código Postal Inválido',
  INVALID_AGE: 'Idade têm de ser acima de 18 anos',
  INVALID_MOBILE_PHONE: 'Telemóvel Inválido',
  INVALID_AGE_RANGE: 'Idade têm de ser entre 1 e 100',
  INVALID_IBAN: 'IBAN Inválido',
  INVALID_ID_NUMBER: 'Nº Cartão Cidadão Inválido',
  ERROR_MBWAY_NOT_INSTALLED: 'Não tens MB WAY no teu telemóvel. Escolhe outro método de pagamento',
  NOT_USER_POLICY: 'Este seguro não está associado a tua wallet',
  ORDER_HAS_POLICY: 'Esta encomenda já tem uma apolice associada',
  QUESTIONNAIRE_NOT_FOUND: 'Questionário não foi encontrado',
  NOT_USER_QUESTIONNAIRE: 'Usuário não autorizado a acessar este questionário',
  ERROR_CREATING_PAYMENT_INTENT: 'Erro ao criar intenção de pagamento',
  ERROR_CREATING_PAYMENT_SUBSCRIPTION: 'Erro ao criar subscrição de pagamento',
  DAO_TYPE_NOT_FOUND: 'Nenhum tipo dao encontrado',
  ID_NOT_PROVIDED: 'ID de entrada não fornecido',
  ERROR_MISSING_ID: 'Pedido inválido',
  ERROR_PAYMENT_TYPE: 'Tipo de pagamento de error',
  MISSING_TRIPSTART_DATE: 'Faltando tripStartDate',
  MISSING_FIELD_EASYPAY: 'Campos em falta exigidos pelo cliente easypay',
  MISSING_INSURANCE_PROVIDER: 'Falta de seguradora',
  UNKNOWN_ORDER_TYPE: 'Tipo de pedido desconhecido',
  UNKNOWN_INSURER: 'Seguradora desconhecida'
}
