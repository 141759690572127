// @flow

import React, { Suspense, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  RouterHistory,
  Switch,
  withRouter,
} from "react-router-dom";
import Cookies from "js-cookie";
import downArrow from "~images/downArrow.svg";
import upArrow from "~images/upArrow.svg";
import Intercom from "react-intercom";

// import "firebase/auth";
// import "firebase/database";
import "./index.less";
import "react-notifications-component/dist/theme.css";
import { LOGIN_USER } from "../../state/user/queries";
import LoginSignupPopup from "../user/login-register-popup";
// Redux Actions
import * as actionTypes from "../../store/actions";
import { Dropdown, Icon, Menu, Spin, Steps } from "antd";

import AuthRoute from "../../components/auth-route";
// Components
// import Intercom from "react-intercom";

import ReactNotification from "react-notifications-component";
import Text from "../../components/text";
import { connect } from "react-redux";
// Icons
import facebookIconImg from "../../images/fab-ico.png";
import futureProofLogo from "../../images/mudey_future.svg";
import mudeyGreyIcon from "../../images/mudeyGreyNew.jpg";
// import firebase from "firebase/app";
// Images
// HOC
import routes from "../../routes";
import twitterIconImg from "../../images/twitterlogomudey.png";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { titleManager } from "../../utils/titleManager";

// const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

const config = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// firebase.initializeApp(config);

type PropsType = {
  client: any,
  history: RouterHistory,
  login: Function,
  logout: Function,
  wallet_token: any
}

function App(props: PropsType) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [showLogin, setShowLogin] = useState(null);
  useEffect(() => {
    titleManager(location);
  }, [location]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        ref &&
          ref.current &&
          ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 300);
    }
    if (show) {
      ref &&
        ref.current &&
        ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [show]);

  const domain =
    process.env.NODE_ENV === "development" ? "localhost" : ".mudey.pt";
  const wallet_token =
    Cookies.get("MUDEY_wallet_token", { domain: domain }) || null;
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  useEffect(() => {
    setShowLogin(!isAuthenticated);
  }, [isAuthenticated]);
  useEffect(() => {}, [showLogin]);
  const authState = useSelector((state: any) => state.status);
  const user = useSelector((state: any) => state.user);
  // const firebaseUser = firebase.auth().currentUser;
  const firebaseAuth = async () => {
    const wallet_token =
      Cookies.get("MUDEY_wallet_token", { domain: domain }) || null;
    const wallet_uuid =
      Cookies.get("MUDEY_wallet_uuid", { domain: domain }) || null;
    const wallet_email =
      Cookies.get("MUDEY_wallet_email", { domain: domain }) || null;
    const wallet_name =
      Cookies.get("MUDEY_wallet_name", { domain: domain }) || null;
    const app_token = Cookies.get("MUDEY_token", { domain: domain });
    // const firebaseUser = firebase.auth().currentUser;
    // if (!firebaseUser && wallet_token) {
    //   Cookies.set(
    //     "MUDEY_token",
    //     wallet_token,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   Cookies.set(
    //     "MUDEY_uuid",
    //     wallet_uuid,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   Cookies.set(
    //     "MUDEY_email",
    //     wallet_email,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   const companyId =
    //     Cookies.get("MUDEY_company", { domain: domain }) || "Mudey";
    //   const managerId = Cookies.get("MUDEY_manager", { domain: domain }) || "";

    //   const { data } = await props.client.query({
    //     query: LOGIN_USER,
    //     fetchPolicy: "no-cache",
    //     variables: {
    //       companyId: companyId,
    //       managerId: managerId,
    //     },
    //   });
    //   Cookies.set(
    //     "MUDEY_name",
    //     data.userLogin.firstName,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   Cookies.set(
    //     "MUDEY_company_handler",
    //     data.userLogin.companyId,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   Cookies.set(
    //     "MUDEY_manager_handler",
    //     data.userLogin.managerId,
    //     { domain: domain },
    //     { expires: 1 },
    //     { sameSite: "strict" },
    //     { secure: true }
    //   );
    //   dispatch({
    //     type: "LOGIN",
    //     payload: {
    //       token: wallet_token,
    //       user: {
    //         email: wallet_email,
    //         name: data.userLogin.firstName,
    //         uuid: wallet_uuid,
    //       },
    //     },
    //   });
    // }
  };
  const footerText  =
    "A MUDEY SA é um mediador de seguros registado na ASF com o nº 420558967, no ramo vida e não vida.";

  useEffect(() => {
    const autoLogin = () => {
      const wallet_token =
        Cookies.get("MUDEY_wallet_token", { domain: domain }) || null;
      setTimeout(() => {
        if (!!wallet_token) {
          // firebaseAuth();
        }
      }, 0);
    };
    if (!user) {
      autoLogin();
    }
  }, [user]);

  const signOut = async () => {
    Cookies.remove("MUDEY_token", { domain: domain });
    Cookies.remove("MUDEY_wallet_token", { domain: domain });
    Cookies.remove("MUDEY_wallet_token");
    Cookies.remove("MUDEY_uuid", { domain: domain });
    Cookies.remove("MUDEY_email", { domain: domain });
    Cookies.remove("MUDEY_name", { domain: domain });
    Cookies.remove("MUDEY_wallet_uuid", { domain: domain });
    Cookies.remove("MUDEY_wallet_email", { domain: domain });
    Cookies.remove("MUDEY_wallet_name", { domain: domain });
    Cookies.remove("MUDEY_company_handler", { domain: domain });
    // firebase.auth().signOut();
    props.logout();
  };

  const renderSteps = () => {
    const { pathname } = location;
    let step = -1;

    if (pathname.indexOf("/simulacao") > -1) step = 0;
    else if (
      pathname.indexOf("/resultados/pacotes") > -1 ||
      pathname.indexOf("/resultados/comparar") > -1
    )
      step = 1;
    else if (
      pathname.indexOf("/compra/resumo") > -1 ||
      pathname.indexOf("/resultados/summary") > -1
    )
      step = 2;

    return step >= 0 ? (
      <Steps current={step} progressDot size="small" style={{ color: "white" }}>
        <Steps.Step title="Questionário" />
        <Steps.Step title="Resultados" />
        <Steps.Step title="Compra" />
        {/* {pathname.indexOf('acidentes-pessoais') !== -1 ? <Steps.Step title="Assinatura" /> : ''}
        {pathname.indexOf('seguro-vida') !== -1 ? <Steps.Step title="Beneficiários" /> : ''} */}
      </Steps>
    ) : (
      <div />
    );
  };

  const userDropdownMenu = (
    <Menu>
      {!wallet_token && (
        <Menu.Item key="0">
          <a href={process.env.REACT_APP_WALLET_URL}> Aceder Wallet</a>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <a onClick={() => signOut()}>Sair</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <ReactNotification />
      <header className="header">
        <div className="container">
          <div className="header-wrapper">
            {/* <div className="header-logo-container"> */}
            <a
              href={process.env.REACT_APP_HP_URL}
              className="header-logo-container"
            >
              <img
                alt="Mudey"
                src={futureProofLogo}
                title="Mudey"
                className="header-logo header-logo-mobile"
              />
              {/* <div>
                <p>powered by</p>
                <p>mudey</p>
              </div> */}
            </a>
            {/* </div> */}

            <div className="language-changer">
              <Text
                align="center"
                color={
                  i18n.language === "pt" ? "#425f74" : "rgba(0, 0, 0, 0.4)"
                }
                onClick={() => changeLanguage("pt")}
                size={14}
                uppercase
              >
                pt
              </Text>
              <Text
                align="center"
                color={
                  i18n.language === "en" ? "#425f74" : "rgba(0, 0, 0, 0.4)"
                }
                onClick={() => changeLanguage("en")}
                size={14}
                uppercase
              >
                en
              </Text>
            </div>
            {renderSteps()}
            {/* {authState === 'in' ? (
              <>
                <Dropdown
                  overlay={userDropdownMenu}
                  placement="bottomRight"
                  trigger={['click']}
                  className="desktop-version">
                  <a className="ant-dropdown-link " onClick={(e: any) => e.preventDefault()}>
                    <Icon type="user" />
                  </a>
                </Dropdown>

                <Dropdown
                  overlay={userDropdownMenu}
                  placement="bottomRight"
                  trigger={['click']}
                  className="mobile-version">
                  <ul className="hamburger-ul " onClick={(e: any) => e.preventDefault()} trigger={['click']}>
                    <li />
                    <li />
                    <li />
                  </ul>
                </Dropdown>

              </>
            ) : (
              ''
            )} */}
            {/* <div className="dropdown-hamburger mobile-version">{userDropdownMenu}</div> */}
          </div>
        </div>
      </header>

      <main className="main">
        {authState === "loading" ? (
          <div className="main-spinner">
            <Spin size="large" />
          </div>
        ) : (
          <div className="container">
            <Suspense fallback={<React.Fragment />}>
              <Switch>
                {/* <Redirect exact from="/" to="/seguro-vida-protecao/simulacao" /> */}

                {routes.map((route: Object, index: number) => {
                  if (route.authRequired) {
                    return (
                      <AuthRoute
                        component={route.component}
                        exact={route.exact}
                        key={index}
                        name={route.name}
                        path={route.path}
                        title={route.title}
                      />
                    );
                  }
                  // else if (route.path == '/' && route.name == 'PPRJourney') {
                  //   return <Redirect to={{ pathname: '/seguro-ppr/simulacao' }} />
                  // }
                  else {
                    return (
                      <Route
                        component={route.component}
                        exact={route.exact}
                        key={index}
                        name={route.name}
                        path={route.path}
                        title={route.title}
                      />
                    );
                  }
                })}
                <Redirect to="/not-found" />
              </Switch>
            </Suspense>
          </div>
        )}
      </main>
      {/* <LoginSignupPopup
        history={history}
        onClose={() => {
          console.log('clicked')
          setShowLogin(false)
        // redirectTo='/'
        show={showLogin}
      /> */}

      <footer className={`footer ${show ? "footer-increased" : ""}`}>
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-wrapper-iob">
              <img
                alt="Mudey Logo"
                className="footer-logo"
                src={mudeyGreyIcon}
                title="Mudey Logo"
              />
              <span>
                &copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}.{" "}
                {t("footer.rights")}
              </span>
              {/* <img alt="Flag Icon" className="footer-flag" src={portugalFlagIcon} /> */}
              {/* <span>{t('footer.created')}</span> */}
            </div>

            <div className="footer-wrapper-col">
              <p style={{ textAlign: "center" }}> {footerText}</p>
            </div>
            <p
              className="main-text"
              onClick={() => setShow(!show)}
            >
              Informação Legal
              {!show ? (
                <img src={downArrow} className="arrow-img-down" />
              ) : (
                <img src={upArrow} className="arrow-img-up" />
              )}
            </p>
            {show && (
              <>
                <div className="hyperlinks-wrapper">
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-de-privacidade/"
                    >
                      Politica de Privacidade
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-cookies/"
                    >
                      Política de Cookies
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/termos-de-utilizacao/"
                    >
                      Termos de Uso
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-tratamento/"
                    >
                      Política de Tratamento
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/aviso-privacidade/"
                    >
                      Aviso de Privacidade
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/politica-pagamentos/"
                    >
                      Regulamento sobre Pagamentos
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/gestao-de-reclamacoes/"
                    >
                      Gestão de Reclamações
                    </a>
                  </span>
                  <span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mudey.pt/wp-content/uploads/2022/02/Ficha-Informativa-V9.pdf"
                      // ref={ref}
                    >
                      Deveres Especiais de Informação
                    </a>
                  </span>
                </div>
                <div className="hyperlinks-end-div" ref={ref} />
              </>
            )}
          </div>
        </div>
        <div className="app">
          <Intercom appID="mw4r6tx3" />
        </div>
      </footer>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    login: (payload: Object) =>
      dispatch({ type: actionTypes.LOGIN, payload: payload }),
    logout: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(App)
);
