import gql from 'graphql-tag'

export const ADD_ORDER = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPrice: ID!, $orderType: OrderType) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPrice: $uuidPrice, orderType: $orderType }) {
      uuid
    }
  }
`
export const ADD_ORDER_BIKE = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`
export const ADD_ORDER_CRITICALILLNESS = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType, $price: String, $uuidJourney: ID!) {
    instaOrderAdd(
      input: { uuidPackage: $uuidPackage, orderType: $orderType, price: $price, uuidJourney: $uuidJourney }
    ) {
      uuid
    }
  }
`
export const ADD_ORDER_HOSPITALIZATION = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType, $price: String, $uuidJourney: ID!) {
    instaOrderAdd(
      input: { uuidPackage: $uuidPackage, orderType: $orderType, price: $price, uuidJourney: $uuidJourney }
    ) {
      uuid
    }
  }
`
export const ADD_ORDER_CHILD = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`
export const ADD_ORDER_SCOOTER = gql`
  mutation instaOrderAdd($uuidPackage: ID!, $orderType: OrderType) {
    instaOrderAdd(input: { uuidPackage: $uuidPackage, orderType: $orderType }) {
      uuid
    }
  }
`

export const ADD_ORDER_LIFE = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const ADD_ORDER_HEALTH = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_DENTAL = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_HOUSEKEEPER = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const ADD_ORDER_CAR = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`
export const ADD_ORDER_PERSONALACCIDENT = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_MORTGAGE = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const ADD_ORDER_FUTURE = gql`
  mutation instaOrderAdd(
    $uuidJourney: ID!,
    $uuidPackage: ID!,
    $orderType: OrderType,
    $price: String,
    $companyId: String
  ) {
    instaOrderAdd(
        input: { 
            uuidJourney: $uuidJourney, 
            uuidPackage: $uuidPackage, 
            orderType: $orderType,
            price: $price,
            companyId: $companyId
        }) {
        uuid
      }
  }
`

export const ORDER_PAY = gql`
  mutation orderPay(
    $uuid: ID!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $name: String!
    $email: String!
    $accountHolder: String
    $iban: String
    $phone: String
    $phoneIndicative: String
  ) {
    orderPay(
      input: {
        uuid: $uuid
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        name: $name
        email: $email
        accountHolder: $accountHolder
        iban: $iban
        phone: $phone
        phoneIndicative: $phoneIndicative
      }
    ) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`
export const CONFIRM_CC_PAYMENT = gql`
  mutation confirmCCPayment(
    $authorization: String
    $value: Float
    $entity: Int
    $reference: Int
    $tkey: ID!
    $status: String
  ) {
    confirmCCPayment(
      input: {
        authorization: $authorization
        value: $value
        entity: $entity
        reference: $reference
        tkey: $tkey
        status: $status
      }
    ) {
      uuid
      paymentInfo {
        extra {
          includesSign
          redirect
          url
        }
        status
      }
      orderType
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation orderUpdate(
    $uuidOrder: ID!
    $additionalAttributes: OrderAdditionalAttributesInput
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
  ) {
    orderUpdate(
      input: {
        uuidOrder: $uuidOrder
        additionalAttributes: $additionalAttributes
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
      }
    ) {
      uuid
      additionalAttributes {
        idNumber
        licenseDate
        licenseNumber
        plate
        model
        brand
        version
        extrasValue
        tow
        usage
        creditInstitution
        correspondenceAddress
        correspondencePostalCode
      }
    }
  }
`

export const INSTA_UPDATE_ORDER = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
      }
    ) {
      uuid
    }
  }
`
export const INSTA_ORDER_PAY = gql`
  mutation instaOrderPay(
    $uuid: ID!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $name: String!
    $email: String!
    $accountHolder: String
    $iban: String
    $phone: String
    $phoneIndicative: String
  ) {
    instaOrderPay(
      input: {
        uuid: $uuid
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        name: $name
        email: $email
        accountHolder: $accountHolder
        iban: $iban
        phone: $phone
        phoneIndicative: $phoneIndicative
      }
    ) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`

export const ADD_ORDER_PPR = gql`
  mutation orderAdd($uuidJourney: ID!, $uuidPackage: ID, $orderType: OrderType, $price: String) {
    orderAdd(input: { uuidJourney: $uuidJourney, uuidPackage: $uuidPackage, orderType: $orderType, price: $price }) {
      uuid
    }
  }
`

export const UPDATE_ORDER_PPR = gql`
  mutation orderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $insuranceStartDate: Date
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $periodPayment: PeriodPayment
    $valuePayment: String
    $additionalAttributes: OrderAdditionalAttributesInput
  ) {
    orderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        insuranceStartDate: $insuranceStartDate
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        periodPayment: $periodPayment
        valuePayment: $valuePayment
        additionalAttributes: $additionalAttributes
      }
    ) {
      uuid
    }
  }
`
