// @flow

import './index.less'

import Button from '../../components/button'
import React from 'react'
import { Spin } from 'antd'
import Text from '../../components/text'
import { useTranslation } from 'react-i18next'

type PropsType = {
  loading: boolean,
  hasBackButton: boolean,
  icon: any,
  paymentInfo: Object,
  titleContent: string
}

const ErrorPage = ({ loading = false, hasBackButton = true, icon, titleContent }: PropsType) => {
  const { t } = useTranslation()

  const title = (
    <>
      <Text className="title-content" color="#895d85" component="p" weight="bold">
        {titleContent}
      </Text>
    </>
  )

  const footer = (
    <div className="button-container">
      <Button onClick={() => (window.location.href = '/')} size="large">
        Voltar à página principal
      </Button>
    </div>
  )

  return (
    <div className="error-page">
      <Spin size="large" spinning={loading}>
        <div className="error-content">
          {icon && <img className="icon-error" src={icon} />}
          {title}
          {hasBackButton ? footer : ''}
        </div>
      </Spin>
    </div>
  )
}

export default ErrorPage
